import NotFound404Icon from 'components/icon/404-icon';
import Link from 'components/link';
import React from 'react';
import styles from './style.module.scss';
import { useThemeContext } from 'contexts';


export default function NotFound404Page() {
  const { theme } = useThemeContext();
  const contactEmail = theme.organization.email();
  const emailLink = `mailto:${contactEmail}`;
  
  return (
    <div className={styles.component}>
      <div className={styles['pin-wrapper']}>
        <span>404</span>
        <NotFound404Icon />
      </div>
      <div className={styles['text-wrapper']}>
        <div className={styles.heading}>Page not found!</div>
        <div className={styles.content}>
          <p>This page may have been removed, or the URL you are using is incorrect.</p>
          <p>Go back to our <Link href="/">home page</Link>, or get in touch at <Link href={emailLink}>{contactEmail}</Link></p>
        </div>
      </div>
    </div> 
  );
}
