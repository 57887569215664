import { COMMERCIAL_TARGETED_URL_PROPERTY_TYPE,
  CONDO_TARGETED_URL_PROPERTY_TYPE,
  FARMS_TARGETED_URL_PROPERTY_TYPE,
  HOMES_TARGETED_URL_PROPERTY_TYPE, 
  HOUSES_TARGETED_URL_PROPERTY_TYPE, 
  LAND_TARGETED_URL_PROPERTY_TYPE, 
  REAL_ESTATE_TARGETED_URL_PROPERTY_TYPE, 
  TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE,
  isForSaleOrRent, 
  getTargetedUrlPropertyTypeSelection,
} from 'utils/listing-query-helper';
import { areaName } from 'components/dynamic-page/area-listings-page/build-head-tags';
import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import formatNumber from 'utils/format-number';

import { AVAILABLE_STATUS, NOT_AVAILABLE_SOLD_STATUS, type ListingParams } from 'contexts/preferences/listing-params/types';
import type { RouteMatchObject } from 'components/dynamic-page/route-matchers';

function areaNameWithCity(routeMatchObject: RouteMatchObject) {
  let areaNameWithCity = areaName(routeMatchObject);
  const { city, locationType } = routeMatchObject;
  if (areaNameWithCity && city && locationType === 'neighbourhood') {
    areaNameWithCity += `, ${deDasherize(city)}`;
  }
  return areaNameWithCity;
}

export default function generateHeading(listingParams: ListingParams, routeMatchObject: RouteMatchObject, totalCount: number, areaLevels?: string[], activeAreaLevel?: number) {
  const filter = listingParams.filter;
  let listingsArea = areaNameWithCity(routeMatchObject);
  const routeMatchLocationType = routeMatchObject.locationType || routeMatchObject.city && 'city' || routeMatchObject.province && 'province';
  if (areaLevels !== undefined && activeAreaLevel !== undefined && activeAreaLevel !== areaLevels.indexOf(routeMatchLocationType)) {
    listingsArea = capitalizeWords(deDasherize(routeMatchObject[areaLevels[activeAreaLevel]] || 'Canada'));
  }
  const forSaleOrRent = isForSaleOrRent(listingParams.filter.rental);
  const homeTypeSelection = getTargetedUrlPropertyTypeSelection(routeMatchObject.filter.homeType);
  const countFormatted = formatNumber(totalCount);
  const house = filter.homeType.house;
  const townhouse = filter.homeType.townhouse;
  const condo = filter.homeType.condo;
  const farm = filter.homeType.farm;
  const commercial = filter.homeType.commercial;
  const land = filter.homeType.land;
  let homeType = homeTypeSelection === HOMES_TARGETED_URL_PROPERTY_TYPE ? 'Real Estate' : homeTypeSelection;

  if (house && !townhouse && !condo && !farm && !commercial && !land) {
    homeType = HOUSES_TARGETED_URL_PROPERTY_TYPE;
  } else if (!house && townhouse && !condo && !farm && !commercial && !land) {
    homeType = TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE;
  } else if (!house && !townhouse && condo && !farm && !commercial && !land) {
    homeType = CONDO_TARGETED_URL_PROPERTY_TYPE;
  } else if (!house && !townhouse && !condo && farm && !commercial && !land) {
    homeType = FARMS_TARGETED_URL_PROPERTY_TYPE;
  } else if (!house && !townhouse && !condo && !farm && commercial && !land) {
    homeType = COMMERCIAL_TARGETED_URL_PROPERTY_TYPE;
  } else if (!house && !townhouse && !condo && !farm && !commercial && land) {
    homeType = LAND_TARGETED_URL_PROPERTY_TYPE;
  } else {
    homeType = REAL_ESTATE_TARGETED_URL_PROPERTY_TYPE;
  }

  if (filter.status !== AVAILABLE_STATUS) {
    let type = filter.status === NOT_AVAILABLE_SOLD_STATUS ? 'Sold' : '';
    if (filter.rental) {
      type = 'Rental';
    }
    if (type == '') {
      return capitalizeWords(`${listingsArea} Past ${type} Listings`);
    } else {
      return capitalizeWords(`${listingsArea} Recently ${filter.rental ? 'Rented' : 'Sold'} ${homeType}`);
    }
  } else {
    let message;
    if (routeMatchObject.locationType === 'street') {
      message = `${countFormatted}+ ${homeType} ${forSaleOrRent} on ${listingsArea}`;
    } else if (listingsArea) {
      message = `${countFormatted}+ ${homeType} ${forSaleOrRent} in ${listingsArea}`;
    } else {
      message = `${countFormatted}+ ${homeType} ${forSaleOrRent}`;
    }
    return capitalizeWords(message);
  }
}
