import { cloneDeep } from 'lodash';
import defaultListingParams from 'contexts/preferences/listing-params/defaults';
import { formatQueryIntoURLQueryParams, getTargetedUrlPropertyTypeSelection } from 'utils/listing-query-helper';
import { generateRouteMatchObjectFromPath, RouteMatchObject } from '../route-matchers';

import type { ListingParams } from 'contexts/preferences/listing-params/types';

export default function updateTargetedUrl(filters: ListingParams['filter'], pageNumber: number) {
  let pageParam = pageNumber > 1 ? `&page=${pageNumber}` : '';
  const routeMatchObject = generateRouteMatchObjectFromPath(window.location.pathname);
  const filterPath = '/' + grabPathName(window.location.pathname, routeMatchObject) + `/filter?${formatQueryIntoURLQueryParams(filters)}${pageParam}`;
  const defaultFilters = defaultListingParams.filter;
  const isHomeDetachedTheSame = filters.homeType.house;
  const isTownhouseTheSame = filters.homeType.townhouse === defaultFilters.homeType.townhouse;
  const isCondoTheSame = filters.homeType.condo === defaultFilters.homeType.condo;
  const isBedroomTheSame = filters.bedrooms === defaultFilters.bedrooms;
  const isBathroomTheSame = filters.bathrooms === defaultFilters.bathrooms;
  const isWaterfrontTheSame = filters.waterfront === defaultFilters.waterfront;
  const isGarageTheSame = filters.garage === defaultFilters.garage;
  const isPoolTheSame = filters.pool === defaultFilters.pool;
  const isOpenHouseTheSame = filters.openHouse === defaultFilters.openHouse;

  // If more than 1 home-type is different -> /filter?
  const homeTypeComparison = [isHomeDetachedTheSame, isTownhouseTheSame, isCondoTheSame].filter(value => !value).length;
  if (homeTypeComparison === 1) {
    replaceFilterUrl(filterPath);
    return 'filters-path';
  }

  // If not then, if more than 1 secondary type is different
  const isBedroomAmountTargeted = parseInt(filters.bedrooms) <= 6;
  const isBathroomAmountTargeted = parseInt(filters.bathrooms) <= 6;
  const secondaryFilterComparison = [isBedroomTheSame, isBathroomTheSame, isWaterfrontTheSame, isGarageTheSame, isPoolTheSame, isOpenHouseTheSame, isBedroomAmountTargeted, isBathroomAmountTargeted].filter(value => !value).length;
  if (secondaryFilterComparison > 1) {
    replaceFilterUrl(filterPath);
    return 'filters-path';
  }
  
  // If not then check if anything else is different
  // ----- Remove above filters from both, then compare with JSON.stringify, if not equal than -> /filter?
  const tmpDefaultFilters: Record<string, unknown> = cloneDeep(defaultFilters);
  const tmpFilters: Record<string, unknown> = cloneDeep(filters);
  const keysToRemove = ['rental', 'homeType', 'bedrooms', 'bathrooms', 'waterfront', 'garage', 'pool', 'openHouse', 'areaName', 'slug', 'status'];
  keysToRemove.forEach(key => {
    delete tmpDefaultFilters[key];
    delete tmpFilters[key];
  });
  if (JSON.stringify(tmpDefaultFilters) !== JSON.stringify(tmpFilters)) {
    replaceFilterUrl(filterPath);
    return 'filters-path';
  }

  // If not the above, then we should be good to use targeted urls
  // ----- Get location prefix
  const { city, provinceCode, province } = routeMatchObject;
  const locationPrefix = city ? `${city}-${provinceCode}-` : province ? `${province}-` : '';
  // ----- Check which home type is different - if not check if open house is true
  let activeHomeType = '/' + getTargetedUrlPropertyTypeSelection(filters.homeType).toLowerCase();
  if (activeHomeType === '/homes') {
    if (!isOpenHouseTheSame) {
      activeHomeType = '/open-houses';
    } else {
      activeHomeType = '';
    }
  }
  // ----- Check if is rental or not
  const rental = filters.rental ? '/for-rent' : '';
  // ----- Check which secondary filters is different unless area is neighbourhood/street
  let subLocation = '';
  const { neighbourhood, street } = routeMatchObject;
  const isNeighbourhoodOrStreet = neighbourhood || street;
  if (isNeighbourhoodOrStreet) {
    if (street && neighbourhood) {
      subLocation = `/${neighbourhood}/${street}`;
    } else {
      subLocation = `/${neighbourhood}`;
    }
  }
  let secondaryFilter = '';
  if (!isBedroomTheSame) {
    secondaryFilter = `/${parseInt(filters.bedrooms)}-bedroom`;
  } else if (!isBathroomTheSame) {
    secondaryFilter = `/${parseInt(filters.bathrooms)}-bathroom`;
  } else if (!isWaterfrontTheSame) {
    secondaryFilter = '/on-waterfront';
  } else if (!isGarageTheSame) {
    secondaryFilter = '/with-garage';
  } else if (!isPoolTheSame) {
    secondaryFilter = '/with-swimming-pool';
  }
  // ----- Check if status is sold, and if activehometype is default and if rental is default
  let statusFilter = '';
  if (filters.status === 'not-available-sold') {
    statusFilter = filters.rental ? '/leased' : '/sold';
  } else if (filters.status.includes('not-available')) {
    statusFilter = filters.rental ? '/expired-listings' : '/past-listings';
  }
  const primaryFilter = `${rental}${statusFilter}${activeHomeType}`;

  // --- Check if page number exists
  pageParam = pageNumber > 1 ? `?page=${pageNumber}` : '';
  
  // --- Set targeted url
  const targetedPath = `/${locationPrefix}real-estate${subLocation}${primaryFilter}${secondaryFilter}${pageParam}`;
  if (window.location.pathname + window.location.search !== targetedPath) {
    window.history.pushState({ ...window.history.state }, '', targetedPath);
  }
  return targetedPath;
}

export function grabPathName(windowPathName: string, routeMatchObject: RouteMatchObject) {
  const { neighbourhood, street } = routeMatchObject;
  const pathSplit = windowPathName.split('/');
  let pathName = pathSplit[1];
  if (neighbourhood && street) {
    pathName = `${pathSplit[1]}/${neighbourhood}/${street}`;
  } else if (neighbourhood) {
    pathName = `${pathSplit[1]}/${neighbourhood}`;
  }
  return pathName;
}

function replaceFilterUrl(filterPath: string) {
  if (window.location.pathname + window.location.search !== filterPath) {
    window.history.replaceState({ ...window.history.state, as: filterPath, url: filterPath }, '', filterPath);
  }
}
