import React, { useEffect, useState } from 'react';
import { loadScript, loadStylesheet } from 'utils/load-file';
import LoadingSpinner from 'components/loading-spinner';
import { testIds } from 'constants/test-constants';
import { Modal, useModal } from 'contexts/modal';
import { EmberDetail, useNotificationFromEmber } from 'utils/ember-communication';

import type { GetServerSidePropsResult } from 'next';

export type emberPageServerSidePropstype = {
    headTags: null;
};

export async function getServerSideProps(): Promise<GetServerSidePropsResult<emberPageServerSidePropstype>> {
  return {
    props: {
      headTags: null,
    },
  };
}

export default function EmberPage() {
  const [isLoading, setIsLoading] = useState(true);
  const { openModal } = useModal();

  useNotificationFromEmber(({ type, data }: EmberDetail) => {
    if (type.match(/^modal-/)) {
      const modal = type.replace('modal-', '') as Modal['name'];
      openModal(modal, data as Record<string, string>);
    }
  });

  const setEmberDisplay = (value: 'block' | 'none') => {
    const element = document.querySelector<HTMLDivElement>('#ember-next');
    if (element) {
      element.style.display = value;
    }
  };

  useEffect(() => {
    Promise.all([
      loadStylesheet('/assets/vendor-3e84dafc860148cc9d77ad85fa9249f6.css'),
      loadStylesheet('/assets/zoocasa-ember-3e84dafc860148cc9d77ad85fa9249f6.css'),
      loadScript({ source: '/assets/vendor-3e84dafc860148cc9d77ad85fa9249f6.js', async: false }),
      loadScript({ source: '/assets/zoocasa-ember-3e84dafc860148cc9d77ad85fa9249f6.js', async: false }),
      loadScript({ source: 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js', async: false }),
    ]).then(() => {
      setIsLoading(false);
      setEmberDisplay('block');
    });
    return () => setEmberDisplay('none');
  }, []);

  return <>
    {isLoading && <LoadingSpinner />}
    <div data-testid={testIds.emberNext} id="ember-next" className="ember" />
  </>;
}
