import React, { MouseEvent } from 'react';
import { useThemeContext } from 'contexts';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const NotFound404Icon = ({ className, onClick }: Props) => {
  const { theme } = useThemeContext();
  const pinHeadColour = theme.colors.notFound404PinHeadColor;
  const pinBodyColour = theme.colors.notFOund404PinBodyColor;

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={167} height={308} className={className} onClick={onClick}>
        <defs>
          <path id="a" d="M0 307.606h166.26V.199H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(0 .224)">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a" />
            </mask>
            <path fill={pinBodyColour} d="M153.586 127.356a82.546 82.546 0 0 0 11.391-29.525c.935-4.292 1.4-8.549 1.242-12.718.014-.606.043-1.21.043-1.82 0-45.892-37.197-83.096-83.082-83.096L83.13.2l-.047-.002C37.196.197 0 37.401 0 83.293c0 .61.03 1.214.043 1.82-.159 4.169.307 8.426 1.242 12.718a82.546 82.546 0 0 0 11.39 29.525c5.93 11.465 20.657 25.37 30.158 39.639.56 1.188 1.2 2.532 1.966 4.115 7.771 16.012 38.032 134.653 38.285 136.496 0 .003.02-.076.047-.199.029.123.05.202.05.2.253-1.844 30.512-120.485 38.283-136.497a394.045 394.045 0 0 0 1.966-4.115c9.504-14.268 24.23-28.174 30.157-39.639" mask="url(#b)" />
          </g>
          <path fill={pinHeadColour} d="M8.194 84.029c0 41.406 33.56 74.97 74.958 74.97 41.399 0 74.958-33.564 74.958-74.97s-33.56-74.97-74.958-74.97c-41.399 0-74.958 33.564-74.958 74.97" />
        </g>
      </svg>
    </>
  );
};

export default NotFound404Icon;
