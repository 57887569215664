import React from 'react';
import RadioButton from 'components/control/radio-button';
import { statusOptions } from 'utils/select-options';
import styles from './style.module.scss';
import { searchFilterIds } from 'constants/test-constants';

import type { Status } from 'contexts/preferences/listing-params/types';

interface Props {
  isRental: boolean;
  value: Status;
  testId?: string;
  onValueChange: (value: Status) => void;
}

// Todo: being used only in old area page. <AreaListingsPageFilters/>
export default function FilterDropdownStatusOptions({ isRental, value, testId, onValueChange = () => {} }: Props) {
  const options = statusOptions(isRental);

  return (
    <div className={styles.component} data-testid={testId}>
      {options.map(option => (
        <RadioButton
          key={option.value}
          label={`${option.label} Listing`}
          isActive={option.value === value}
          onClick={() => onValueChange(option.value)}
          testId={option.value + searchFilterIds.dropdownOption}
        />
      ))}
    </div>
  );
}
