import React, { useState, useEffect } from 'react';
import Checkbox from 'components/control/checkbox';
import NibIcon from 'components/icon/nib-icon';
import Select from '@zoocasa/node-kit/components/controls/base/select';
import { bedroomSelectionOptions } from 'utils/select-options';
import { parameterContainsIsMore } from 'utils/listing-query-helper';
import styles from './style.module.scss';
import { searchFilterIds } from 'constants/test-constants';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';

import type { Bedroom } from 'contexts/preferences/listing-params/types';

interface Props {
  bedrooms: string;
  closeDropdown?: () => void;
  onValueChange: (arg: string) => void;
  checkboxNamePrefix?: string;
}

// Todo: being used only in old area page. <AreaListingsPageFilters/>
export default function FilterDropdownBedOptions({ bedrooms, closeDropdown, onValueChange, checkboxNamePrefix }: Props) {
  const [bedroomNumber, setBedroomNumber] = useState<Bedroom>(parseInt(bedrooms, 10) as Bedroom);
  const [isMore, setIsMore] = useState(parameterContainsIsMore(bedrooms));

  useEffect(() => {
    onValueChange(`${bedroomNumber}${isMore ? '+' : ''}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bedroomNumber, isMore]);

  useEffect(() => {
    setBedroomNumber(parseInt(bedrooms, 10) as Bedroom);
  }, [bedrooms]);

  return (
    <div className={styles.component} ref={useOutsideClickHandler(closeDropdown!)} >
      <div className={styles['select-field']} data-testid={searchFilterIds.bedOptionsDropdown}>
        <Select
          options={bedroomSelectionOptions}
          value={bedroomNumber}
          onValueChange={setBedroomNumber}
          data-testid={searchFilterIds.bedOptionsDropdownSelect}
        />
        <NibIcon />
      </div>
      <Checkbox label="or more" name={checkboxNamePrefix + 'or-more-beds'} value={isMore} onValueChange={setIsMore} testId={searchFilterIds.orMoreBedCheckboxOption} />
    </div>
  );
}
